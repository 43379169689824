import React, { useEffect, Suspense, lazy } from 'react'
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom'
import './index.css'

const Home = lazy(() => import('../home-pg'))
const Contact = lazy(() => import('../contact-pg'))
const Services = lazy(() => import('../services-pg'))
const AboutUs = lazy(() => import('../about-pg'))
const PageNotFound = lazy(() => import('../page-not-found'))
const Social = lazy(() => import('../social-pg'))
const ScrollToTopBtn = lazy(() => import('../scroll-to-top-btn'))

const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

const NotFound = () => <PageNotFound text="This page does'nt exists!" />

const RotateScreen = () => (
  <div className="rotate">
    <span>Rotate your device</span>
  </div>
)

const Loading = () => <div className="loading"></div>

const App = () => {
  useEffect(() => {
    function getSetVh() {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    if (window && window.innerWidth < 768 && window.innerWidth > window.innerHeight) {
      window.addEventListener('resize', getSetVh)
    }

    return () => {
      window.removeEventListener('resize', getSetVh)
    }
  }, [])

  return (
    <div
      id="app"
      className="light-theme"
      style={{ backgroundColor: 'var(--bg-primary-color)' }}
    >
      <RotateScreen />
      <Router>
        <Suspense fallback={<Loading />}>
          <ScrollToTop />
          <ScrollToTopBtn />
          <Switch>
            <Route path="/" component={Home} exact={true} />
            <Route path="/about-us" component={AboutUs} />
            <Route path="/services" component={Services} />
            <Route path="/contact" component={Contact} />
            <Route path="/social" component={Social} />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Router>
    </div>
  )
}

export default App
